<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu as="div" class="relative z-50 inline-block text-left">
    <div>
      <MenuButton as="a" href="/imei-check" :class="navLinkClasses('/imei-check')">
        {{ $t('nav.checkimei') }}
        <ChevronDownIcon class="w-5 ml-2 -mr-1 h-7" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute w-56 mt-2 origin-top-right transform -translate-x-1/2 bg-white rounded-md shadow-lg left-1/2 ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem v-for="item in imeis" :key="item.name" v-slot="{ active }">
            <a
              :href="item.href"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              >{{ item.name }}</a
            >
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { computed } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import { useRoute } from 'vue-router';

const route = useRoute();

const navLinkClasses = computed(() => (path) => {
  if (path === route.path) {
    return 'bg-gray-600 text-white px-3 py-2 rounded-md text-lg font-medium inline-flex justify-center w-full';
  } else {
    return 'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-lg font-medium inline-flex justify-center w-full';
  }
});

const imeis = [
  {
    name: 'IMEI Check - (All in One)',
    href: '/imei-check',
    active: true,
  },
  { name: 'iPhone IMEI Check', href: '/imei-check', active: true },
  {
    name: 'Apple Serial Check',
    href: '/imei-check',
    active: true,
  },
  { name: 'iCloud(ON/OFF) Check', href: '/imei-check', active: true },
  { name: 'iPhone SimLock Check', href: '/imei-check', active: true },
  {
    name: 'Apple Warranty Check',
    href: '/imei-check',
    active: true,
  },
  {
    name: 'iPhone Carrier Check',
    href: '/imei-check',
    active: true,
  },
  {
    name: 'Samsung IMEI Check',
    href: '/imei-check',
    active: true,
  },
  {
    name: 'IMEI Blacklist Check',
    href: '/imei-check',
    active: true,
  },
  {
    name: 'Sprint IMEI Check(pro)',
    href: '/imei-check',
    active: true,
  },
];
</script>
