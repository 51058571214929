<script setup>
import { onMounted, reactive, computed, ref } from 'vue';
import { useNoticeStore } from '@/stores/notice';
import { useServiceStore } from '@/stores/service';
import { useUnlockStore } from '@/stores/unlock';
import { usePriceStore } from '@/stores/price';
import Control from '@/components/Control.vue';
import TextTruncate from '@/components/TextTruncate.vue';
// import { useCookies } from 'vue3-cookies';
// import { useOrderStore } from '@/stores/order';

const noticeStore = useNoticeStore();
const serviceStore = useServiceStore();
const unlockStore = useUnlockStore();
const priceStore = usePriceStore();
// const { cookies } = useCookies();
// const orderStore = useOrderStore();

const form = reactive({
  serviceId: '',
  imeiValue: '',
  cookieId: '',
});

const unlockForm = reactive({
  unlockId: '',
  imeiValue: '',
});

onMounted(() => {
  noticeStore.index('filter[is_active_eq]=true');
  unlockStore.index();
  priceStore.index();
  // cookies.set('freeOrderCookieId', Math.floor(Math.random() * 10000000000000), '2d');
});

const unlockOptions = computed(() => {
  const options = ref([]);
  const filtered = unlockStore.unlocks.filter((unlock) => unlock.attributes.is_active);
  filtered.forEach((unlock) => {
    options.value.push({
      label: `$${unlock.attributes.price} - ${unlock.attributes.name} - ${unlock.attributes.needed_time}`,
      value: unlock.id,
    });
  });
  return options.value;
});

const priceOptions = computed(() => {
  const options = ref([]);
  priceStore.prices.forEach((price) => {
    options.value.push({
      label: `$${price.attributes.credit} - ${price.attributes.service.name}`,
      value: price.attributes.service.serviceId,
    });
  });
  return options.value;
});

const handleServiceId = () => {
  serviceStore.show(form.serviceId).then((res) => {});
  // userId.value = mainStore.userId;
  // userCredit.value = mainStore.credit;
};

const submit = () => {
  // orderStore.freeCheck(form);
  // router.push('/auth/login');
  window.location.href = '/auth/login';
};

const samples = [
  {
    name: 'Samsung Info (S2)',
    sample: `<p><center>Model Info: MOBILE SM-G975F/DS GREEN O2U<br>IMEI Number: 35617xxxxxxxxxx<br>Master Number: 356171xxxxxxxxx<br>Serial Number: RF8MAxxxxxx<br>DO Number: 84934296xx(C430-03)<br>Model Number: SM-G975FZGDO2U<br>Model Name: SM-G975F/DS<br>Model Desc: GALAXY S10 PLUS (SM-G975F DS)<br>Warranty Status: Out of Warranty<br>Warranty Until: 10.01.2021<br>Manufacturer: Samsung Electronics Vietnam<br>Carrier: United Kingdom O2<br>Sold By: United Kingdom / 01 November 2019</center></p>`,
  },
  {
    name: 'Apple Carrier Check',
    sample: `<p><center>Model: iPhone 11 Pro Max 64GB Midnight Green Cellular [A2161] [iPhone12,5]<br>Description: IPHONE 11 PRO MAX MG 64GB SPR-USA<br>IMEI Number: 3539011092XXXX<br>Serial Number: FCHC157AXXXX<br>IMEI2 Number: 35390110886XXXX<br>MEID Number: 353901109XXXXX<br>Purchase Date: March 9, 2020<br>Device Age: 1 Year, 6 Months and 28 Days<br>Replaced Device: No<br>Locked Carrier: US Sprint/T-Mobile Locked Policy<br>Country: United States<br>SIM-Lock: <span style="color:red">Locked</span></center></p>`,
  },
];
</script>

<template>
  <div class="pt-20 pb-20 text-white bg-blue-800 lt:pt-28">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="text-center">
        <div class="max-w-3xl mx-auto mb-16">
          <div class="mb-10" v-if="noticeStore.notices.length">
            <ul>
              <li v-for="notice in noticeStore.notices" :key="notice.id">
                <span v-html="notice.attributes.content"></span>
              </li>
            </ul>
          </div>

          <div class="mb-16 text-sm font-light">
            <p class="mt-4 mb-3 text-base lg:mx-auto">
              {{ $t('home.p1') }}
            </p>
            <p class="mb-3">{{ $t('home.p3') }}</p>
          </div>

          <div class="mb-9">
            <h2 class="mt-2 mb-3 text-xl font-medium leading-8 tracking-tight text-left sm:text-xl">
              {{ $t('home.title') }}
            </h2>
            <label
              for="search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
              >IMEI Check</label
            >
            <div class="md:grid md:grid-cols-12 md:gap-2">
              <div class="col-span-7 mb-3 text-gray-400 md:mb-0">
                <control
                  v-model="form.imeiValue"
                  name="imeiValue"
                  autocomplete="imeiValue"
                  :placeholder="$t('home.placeholder')"
                />
              </div>
              <div class="col-span-3 text-gray-400">
                <control
                  v-model="form.serviceId"
                  :options="priceOptions"
                  type="select"
                  name="serviceId"
                  autocomplete="serviceId"
                  :placeholder="$t('home.select_services')"
                />
              </div>
              <button
                @click="submit"
                type="submit"
                class="text-white mt-3 md:mt-0 bg-[#ff7c60] hover:bg-[#ff7c60] focus:ring-4 focus:outline-none focus:ring-[#ff7c60] font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#ff7c60] dark:hover:bg-[#ff7c60] dark:focus:ring-[#ff7c60] col-span-2"
              >
                {{ $t('home.button') }}
              </button>
            </div>
          </div>

          <div class="max-w-3xl mx-auto font-light mb-9">
            <p class="text-sm leading-loose text-left text-orange-400">
              {{ $t('home.p5') }}
            </p>
          </div>

          <div>
            <h2 class="mb-3 text-xl font-medium leading-8 tracking-tight text-left sm:text-xl">
              {{ $t('home.unlock_title') }}
            </h2>
            <label
              for="search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
              >Unlock Phone</label
            >
            <div class="md:grid md:grid-cols-12 md:gap-2">
              <div class="col-span-7 mb-3 text-gray-400 md:mb-0">
                <control
                  v-model="unlockForm.imeiValue"
                  name="imeiValue"
                  autocomplete="imeiValue"
                  :placeholder="$t('home.unlock_placeholder')"
                />
              </div>
              <div class="col-span-3 text-gray-400">
                <control
                  v-model="unlockForm.unlockId"
                  :options="unlockOptions"
                  type="select"
                  name="serviceId"
                  autocomplete="serviceId"
                  :placeholder="$t('home.select_unlocks')"
                />
              </div>
              <button
                @click="submit"
                type="submit"
                class="col-span-2 px-4 py-2 mt-3 text-sm font-medium text-white bg-green-500 rounded-lg md:mt-0 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-500 dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-500"
              >
                {{ $t('home.unlock_button') }}
              </button>
            </div>
          </div>
        </div>

        <div class="mb-8 text-red-500">
          <p>
            If you want more iPhone unlock solution, you can use NYturbo unlock Chip/Card to unlock
            most iPhone carriers.
          </p>
          <p>Website: <a href="https://www.nyturbo.com" target="_blank">www.NYturbo.com</a></p>
        </div>

        <div class="container mx-auto text-sm font-light text-center md:px-50">
          <div class="container grid gap-4 md:grid-cols-2">
            <div v-for="item in samples" :key="item.name" class="py-5 text-center md:px-10">
              <h3 class="mb-3 text-base font-normal">{{ item.name }}</h3>
              <TextTruncate :text="item.sample" class-name="omit_line4" btn-class="text-center" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.p-accordion-header-link) {
  display: block !important;
  width: 100%;
  /* justify-content: space-between; */
  /* justify-items: start; */
}
::v-deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
  font-weight: 500;
  color: #666;
  border-left-width: 0;
  border-right-width: 0;
  background: #fff;
}
::v-deep(.p-accordion
    .p-accordion-tab:last-child
    .p-accordion-header:not(.p-highlight)
    .p-accordion-header-link) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
::v-deep(.p-accordion-toggle-icon.pi.pi-chevron-right) {
  display: none !important;
}
::v-deep(.p-accordion-toggle-icon.pi.pi-chevron-down) {
  display: none !important;
}
::v-deep(.p-accordion .p-accordion-content) {
  color: #fff;
  font-weight: 300;
  background: transparent !important;
  border: 0 !important;
}
::v-deep(.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link) {
  background: transparent !important;
  color: #fff;
  border: 0 !important;
}
</style>
