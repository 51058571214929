<template>
  <Listbox as="div" v-model="selected">
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full py-2 pl-3 pr-10 text-left text-gray-200 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <span class="flex items-center">
          <img :src="selected.flag" alt="" class="flex-shrink-0 w-6 h-6 rounded-full" />
          <span class="block ml-3 truncate">{{ selected.name.toUpperCase() }}</span>
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
          <ChevronDownIcon class="w-5 h-5 text-gray-200" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            as="template"
            v-for="locale in availableLocales"
            :key="locale.id"
            :value="locale"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-3 pr-9',
              ]"
            >
              <div class="flex items-center">
                <img :src="locale.flag" alt="" class="flex-shrink-0 w-6 h-6 rounded-full" />
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                  {{ locale.name.toUpperCase() }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
              >
                <CheckIcon class="w-5 h-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/solid';
import { useRoute } from 'vue-router';
import flagUk from '@/images/flags/us.svg';
import flagEs from '@/images/flags/es.svg';
import flagCn from '@/images/flags/cn.svg';
import flagFr from '@/images/flags/fr.svg';

console.debug({ flagUk, flagEs, flagCn, flagFr });

const route = useRoute();

const availableLocales = ref([
  {
    id: 1,
    name: 'en',
    flag: flagUk,
  },
  {
    id: 2,
    name: 'es',
    flag: flagEs,
  },
  {
    id: 3,
    name: 'cn',
    flag: flagCn,
  },
  {
    id: 4,
    name: 'fr',
    flag: flagFr,
  },
]);

const locale = ref(window.I18n.locale);

const localeIndex = computed(() =>
  availableLocales.value.findIndex((e) => e.name === locale.value),
);

const selected = ref(availableLocales.value[localeIndex.value]);

watch(selected, (selected) => {
  let redirectTo = `/${selected.name}${route.path}`;
  if (selected == availableLocales.value[localeIndex.value]) {
    redirectTo = `${route.path}`;
  }
  window.location.href = redirectTo;
});
</script>
