import { createWebHistory, createRouter } from 'vue-router';

import HomeIndex from '@/views/home/index.vue';
import ImeiCheck from '@/views/pages/imei_check.vue';
import Pricing from '@/views/pages/pricing.vue';
import Faq from '@/views/pages/faq.vue';
import Contact from '@/views/pages/contact.vue';
import privacyPolicy from './views/pages/privacy_policy.vue';
import Terms from './views/pages/terms.vue';
import PageIndex from '@/views/pages/index.vue';
import Error404 from '@/views/pages/error_404.vue';
import Error500 from '@/views/pages/error_500.vue';

const router = createRouter({
  history: createWebHistory(`/${I18n.prefix}`),
  routes: [
    { path: '/', component: ImeiCheck, name: 'root_path' },
    { path: '/imei-check', component: ImeiCheck, name: 'imei_check_path' },
    { path: '/pricing', component: Pricing, name: 'pricing_path' },
    { path: '/faq', component: Faq, name: 'faq_path' },
    { path: '/contact', component: Contact, name: 'contact_path' },
    { path: '/privacy-policy', component: privacyPolicy, name: 'privacy_policy_path' },
    { path: '/terms', component: Terms, name: 'terms_path' },
    { path: '/pages', component: PageIndex, name: 'pages_path' },
    { path: '/404', component: Error404 },
    { path: '/5000', component: Error500 },

    { path: '/:catchAll(.*)', redirect: '/404' },
  ],
});

export default router;
